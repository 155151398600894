<template>
  <div
    class="mod"
    id="home"
  >
    <div class="wrap">
      <img
        class="logo"
        src="./images/img_74034_0_1.png"
      />
      <a
        href="#home"
        class="text"
      >{{$t("navbar.home")}}</a>
      <a
        href="#aboutus"
        class="text"
      >{{$t("navbar.aboutus")}}</a>
      <a
        href="#edu"
        class="text"
      >{{$t("navbar.edu")}}</a>
      <a
        href="#mattheweffect"
        class="text"
      >{{$t("navbar.mattheweffect")}}</a>
      <a
        href="#partner"
        class="text"
      >{{$t("navbar.partner")}}</a>
      <a
        href="#contact"
        class="text"
      >{{$t("navbar.contact")}}</a>
      <a
        href="#qa"
        class="text"
      >{{$t("navbar.qa")}}</a>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_en: false,
      translate_pic: require('../images/en.png')
    }
  },
  methods: {
    handleCommand() {
      let command = 'cn'
      if (!this.is_en) {
        command = 'en'
        this.translate_pic = require('../images/en.png')
      } else {
        this.translate_pic = require('../images/ch.png')
      }
      // 点击切换为英文
      if (command === 'en') {
        // command 是element-ui 下拉框的用法，具体可参考官方文档
        this.lang = 'en'
        this.is_en = true // 当点击切换成英文后，此样式生效（因样式问题自己单独写的）
      } else {
        this.lang = 'cn'
        this.is_en = false
      }
      localStorage.lang = command
      this.$i18n.locale = command
      location.reload()
      // this.$store.commit('switchLang', this.lang) // 这里的switchLang是调用store/index.js中mutations里的switchLang
    }
  },

  created() {
    // 点击切换英文后调用此样式（这里是点击英文后页面部分的样式有点变化，我自己单独写的动态class方法）
    if (localStorage.lang === 'en') {
      this.translate_pic = require('../images/ch.png')
      this.is_en = true
    } else {
      this.translate_pic = require('../images/en.png')
      this.is_en = false
    }
  }
}
</script>
<style scoped src="./index.response.css" />
