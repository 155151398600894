<template>
  <div class="Ac2-mod">
    <div class="Ac2-Ac2--ac2">
      <div class="Ac2-Ac2-body">
        <div class="Ac2-Ac2-wrapper-inner">
          <div class="Ac2-Ac2-group">
            <div class="Ac2-Ac2-badge-wrapper">
              <img
                class="Ac2-Ac2-badge"
                src="./images/img_74840_0_0.png"
              />
            </div>
            <div
              v-html="text"
              :class="is_en?'Ac2-Ac2-desc1':'Ac2-Ac2-desc'"
            ></div>
          </div>
          <a href="#activity">

            <div class="Ac2-Ac2-group-1">
              <span class="Ac2-Ac2-detail">{{btn}}</span>
            </div>
          </a>
        </div>
      </div>
      <div class="Ac2-Ac2-footer">
        <div class="Ac2-Ac2-mask" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_en: false,
      text: '',
      btn: '',
      constants: {}
    }
  },
  methods: {},

  created() {
    if (localStorage.lang === 'en') {
      this.is_en = true
      this.text =
        'China-Israel Youth Innovation&<br>Entrepreneurship Contest（YIEC）'
      this.btn = 'Learn more'
    } else {
      this.is_en = false
      this.text = '国际青少年创新创业大赛（YIEC）'
      this.btn = '了解详情'
    }
  }
}
</script>
<style scoped src="./index.response.css" />
