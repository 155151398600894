
// 这里把翻译好的英文以对象的格式包裹起来，建议以模块来划分，这样在调用起来方便效率，举例说明：导航菜单栏
const en = {
  navbar: {  // 取导航的英文来包裹其内的子对象
    'home': 'Home',  //前面的第一个英文home为页面将调用的
    'aboutus': 'About Us',
    'edu': 'Financial Education',
    'mattheweffect': 'Matthew Effect',
    'partner': 'Partners',
    'contact': 'Contact Us',
    'qa': 'Q&A',
  },
  fg: {
    'about': 'About Math FinEdu',
    'about_content': 'Math FinEdu is an online and offline financial education platform. Teenagers will develop financial skills and personal financial management through learning financial and business knowledge, simulating and practicing financial thinking. We are not only cultivating young people how to earn and increase wealth, but also educating teenagers to be better and realize the value of life. Our educational philosophy is derived from Jewish financial wisdom and Israeli innovative and entrepreneurial thinking.',

  },
  qa: {
    'qt1': 'What is financial quotient?',
    'q1': 'Financial quotient is the comprehensive ability to correctly recognize, acquire and manage money. It refers to the ability to make, save, spend, invest and donate money.',
    'qt2': 'Why study financial quotient?',
    'q2': 'Financial quotient is one of the three essential qualities in modern society, along with IQ and EQ. As an economic human ability to survive in the society, financial quotient is considered as the key to a successful life by more and more people. Mastering correct concepts and skills of financial quotient is related to the happiness of life for oneself and family members.',
    'qt3': 'What are the aspects of financial quotient?',
    'q3': 'One is the ability to create wealth and recognize the law of multiplied wealth (namely, values);\nThe second is the ability to manage wealth and apply it.',
    'qt4': 'What are the advantages of Math FinEdu?',
    'q4': "1. Professional and systematic financial, business courses and learning plan;\n2. Combination of online and offline activities to accompany students in long term;\n3.Entertainment learning experience with case study and project practice, providing students with interesting and inspiring financial learning experience;\n4. Improvement of the motivation and enthusiasm to other traditional subjects through comprehensively using interdisciplinary knowledge;\n5.The actual output of the team project; Student’s teamwork ability and leadership will be exercised;\n6. Help students further understand themselves and their future plan, which is essential to their future career development.",
  },
  act: {
    "register": "Register Now"
  }
}
 export default en    // vuex方法，映射到英文上