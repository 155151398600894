<template>
  <div class="Menu-mod">
    <div class="Menu-wrapper-inner">
      <div class="Menu-header">
        <div
          class="Menu-view"
          @click="scrollto('aboutus')"
        >
          <span class="Menu-about">{{$t("navbar.aboutus")}}</span>
          <div class="Menu-mask" />
        </div>
      </div>
      <div class="Menu-body-i0">
        <div class="Menu-overlayer" />
        <div
          class="Menu-financial-education-wrapper"
          @click="scrollto('edu')"
        >
          <span class="Menu-financial-education">{{$t("navbar.edu")}}</span>
        </div>
      </div>
      <div class="Menu-body-i1">
        <div class="Menu-layer" />
        <div
          class="Menu-matthew-effect-wrapper"
          @click="scrollto('mattheweffect')"
        >
          <span class="Menu-matthew-effect">{{$t("navbar.mattheweffect")}}</span>
        </div>
      </div>
      <div class="Menu-body-i2">
        <div class="Menu-mask-1" />
        <div
          class="Menu-partners-wrapper"
          @click="scrollto('partner')"
        >
          <span class="Menu-partners">{{$t("navbar.partner")}}</span>
        </div>
      </div>
      <div class="Menu-body-i3">
        <div class="Menu-overlayer-1" />
        <div
          class="Menu-contact-wrapper"
          @click="scrollto('contact')"
        >
          <span class="Menu-contact">{{$t("navbar.contact")}}</span>
        </div>
      </div>
      <div class="Menu-body-i4">
        <div class="Menu-layer-1" />
        <div
          class="Menu-tag-wrapper"
          @click="scrollto('qa')"
        >
          <span class="Menu-tag">{{$t("navbar.qa")}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    }
  },
  methods: {
    scrollto(id) {
      console.log(2333)
      this.$emit('scrollto', id)
    }
  }
}
</script>
<style src="./index.css" />
