
 // 同理en.js文件，只是这里改成中文了
const cn = {
  navbar: {
    'home': '首页',
    'aboutus': '关于我们',
    'edu': '财商教育',
    'mattheweffect': '马太效应',
    'partner': '合作伙伴',
    'contact': '联系我们',
    'qa': '常见问题',
  },
  fg: {
    'about': '关于马太财商',
    'about_content': '马太财商Math FQ是一个线上下结合的财商教育平台，青少年用户学习金融与商业知识以掌握财商思维，并通过模拟和实践财商思维以掌握财商技能，并妥善管理个人财务。我们不仅仅是培养青少年如何赚取和增加财富，更是如何善待和管理财富做更好的自己并实现人生价值，我们的教育理念源自犹太金融智慧和以色列创新创业思维。',

  },
  qa: {
    'qt1': '什么是财商？',
    'q1': '财商就是正确认知、获取和管理金钱的综合能力。主要是指赚钱、省钱、花钱、投钱、捐钱的能力。',
    'qt2': '为什么要学财商？',
    'q2': '财商是与智商、情商并列的现代社会能力三大不可或缺的素质。作为经济人在经济社会中的生存能力，财商被越来越多的人认为是实现成功人生的关键。掌握正确的财商观念和技能关系到自己和家人的生活幸福。',
    'qt3': '财商包括哪些方面？',
    'q3': '一是创造财富及认识财富倍增规律的能力（即价值观）；\n 二是驾驭财富及应用财富的能力。',
    'qt4': '马太财商有哪些优势？',
    'q4': "1. 专业系统化的财商课程及学习体系；\n 2. 线上线下结合，陪伴学员长期成长；\n 3. 寓教于乐，案例与项目实践为学员提供有趣有启发的财商学习体验；\n 4. 学员可综合运用跨学科知识，提高其他文化科目学习动力和积极性；\n 5.实际的团队项目成果输出，让学员团队协作能力与领导力得到锻炼；\n 6. 透过课程帮助学员更认识自己及规划未来，利于其将来的职业发展。",
  },
  act: {
    "register": "立即报名"
  }
}
export default cn