import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'  // 这里是调用 langs/index.js

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.lang || 'cn',   // 语言标识
  messages
})

export default i18n