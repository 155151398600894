<template>
  <div class="qa-mod">

    <div class="qa-header-i0">
      <span class="qa-tag">{{$t("qa.qt1")}}</span>
      <img
        @click="show1=!show1"
        class="qa-large-icon"
        :src="show1?close:open"
      />
    </div>
    <div
      class="qa-container"
      v-if="show1"
    >
      <span class="qa-content">
        {{$t("qa.q1")}}
      </span>
    </div>
    <div class="qa-header-i1">
      <span class="qa-info">{{$t("qa.qt2")}}</span>
      <img
        class="qa-large-icon-1"
        @click="show2=!show2"
        :src="show2?close:open"
      />
    </div>
    <div
      class="qa-container"
      v-if="show2"
    >
      <span class="qa-content">
        {{$t("qa.q2")}}
      </span>
    </div>
    <div class="qa-header-i2">
      <span class="qa-meta">{{$t("qa.qt3")}}</span>
      <img
        class="qa-large-icon-2"
        @click="show3=!show3"
        :src="show3?close:open"
      />
    </div>
    <div
      class="qa-container"
      v-if="show3"
    >
      <span class="qa-content">
        {{$t("qa.q3")}}
      </span>
    </div>
    <div class="qa-header-i3">
      <span class="qa-subtitle">{{$t("qa.qt4")}}</span>
      <img
        class="qa-large-icon-3"
        @click="show4=!show4"
        :src="show4?close:open"
      />
    </div>
    <div
      class="qa-container"
      v-if="show4"
    >
      <span class="qa-content">
        {{$t("qa.q4")}}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      close: require('./images/ic_close.png'),
      open: require('./images/ic_open.png'),
      constants: {}
    }
  },
  methods: {}
}
</script>
<style scoped src="./index.response.css" />
