<template>
  <div class="mod">

    <Navbar></Navbar>
    <Banner v-if="!hash.includes('activity')"></Banner>

    <img
      v-if="hash.includes('activity')"
      :src='activity_url'
      style="width:100vw;margin-top:12vw"
    />
    <div
      class="reigster"
      v-if="hash.includes('activity')"
    >
      <a
        href="https://www.huodongxing.com/go/FutureStarUp"
        target="_blank"
        class="register_btn"
      >
        <span>{{$t("act.register")}}</span>
      </a>
    </div>
    <div
      class="box "
      v-if="!hash.includes('activity')"
    >

      <div class="bd ">

        <div class="layerWrap">

          <slider
            ref="slider"
            :options="options"
          >

            <slideritem
              v-for="(item,index) in bannerList"
              :key="index"
            >
              <img
                class="layer"
                :src="item"
              />
            </slideritem>

          </slider>
        </div>
      </div>
      <div
        class="content"
        v-if="!is_en"
      >
        <img
          id="aboutus"
          src="./mobile/images/mbg1.png"
        />

        <img
          id="edu"
          src="./mobile/images/mbg2.png"
        />
        <img
          id="mattheweffect"
          src="./mobile/images/mbg3.png"
        />
        <img
          id="partner"
          src="./mobile/images/mbg4.png"
        />

        <img
          id="contact"
          src="./mobile/images/mbg5.png"
        />
        <Qa></Qa>
      </div>
      <div
        class="content"
        v-if="is_en"
      >
        <img
          id="aboutus"
          src="./mobile/images/mbg1en.png"
        />

        <img
          id="edu"
          src="./mobile/images/mbg2en.png"
        />
        <img
          id="mattheweffect"
          src="./mobile/images/mbg3en.png"
        />
        <img
          id="partner"
          src="./mobile/images/mbg4en.png"
        />

        <img
          id="contact"
          src="./mobile/images/mbg5en.png"
        />
        <Qa></Qa>
      </div>
    </div>
    <Foot></Foot>

  </div>
</template>
<script>
import { slider, slideritem } from 'vue-concise-slider'
import Navbar from './mobile/Navbar/index'
import Qa from './mobile/Qaq/index'
import Foot from './mobile/Foot/index'
import Banner from './mobile/Banner/index'
export default {
  components: { slider, slideritem, Navbar, Qa, Banner, Foot },
  data() {
    return {
      is_en: false,
      bannerList: [],
      activity_url: '',
      hash: '',
      //Slider configuration [obj]
      options: {
        pagination: true,
        thresholdDistance: 100, // Sliding distance threshold
        thresholdTime: 300, // Sliding time threshold decision
        grabCursor: false, // Scratch style
        speed: 1000, // Sliding speed
        // timingFunction: 'ease', // Sliding mode
        loop: true, // Infinite loop
        autoplay: 6000 // Auto play[ms]
      },
      constants: {
        //  document.querySelector("#你设置的位置").scrollIntoView(true);
      }
    }
  },
  methods: {},

  created() {
    // 点击切换英文后调用此样式（这里是点击英文后页面部分的样式有点变化，我自己单独写的动态class方法）
    if (localStorage.lang === 'en') {
      this.is_en = true
      this.activity_url = require('./images/mactivity_en.png')
      this.bannerList.push(require('./images/banner1-en.png'))
      this.bannerList.push(require('./images/banner2-en.png'))
      this.bannerList.push(require('./images/banner3-en.png'))
    } else {
      this.activity_url = require('./images/mactivity.png')
      this.is_en = false
      this.bannerList.push(require('./images/banner1.png'))
      this.bannerList.push(require('./images/banner2.png'))
      this.bannerList.push(require('./images/banner3.png'))
    }
    window.addEventListener('hashchange', () => {
      this.hash = location.hash
      console.log(location.hash)
    })
  }
}
</script>
<style scoped src="./mobile.css" />
