<template>
  <div class="Menu2-mod">
    <div class="Menu2-wrapper-inner">
      <div class="Menu2-header">
        <div
          class="Menu2-view"
          @click="scrollto('aboutus')"
        >
          <span class="Menu2-tag">关于我们</span>
          <div class="Menu2-mask" />
        </div>
      </div>
      <div class="Menu2-body-i0">
        <div class="Menu2-overlayer" />
        <div
          class="Menu2-label-wrapper"
          @click="scrollto('edu')"
        >
          <span class="Menu2-label">财商教育</span>
        </div>
      </div>
      <div class="Menu2-body-i1">
        <div class="Menu2-layer" />
        <div
          class="Menu2-word-wrapper"
          @click="scrollto('mattheweffect')"
        >
          <span class="Menu2-word">马太效应</span>
        </div>
      </div>
      <div class="Menu2-body-i2">
        <div class="Menu2-mask-1" />
        <div
          class="Menu2-tag-wrapper"
          @click="scrollto('partner')"
        >
          <span class="Menu2-tag-1">合作伙伴</span>
        </div>
      </div>
      <div class="Menu2-body-i3">
        <div class="Menu2-overlayer-1" />
        <div
          class="Menu2-label-wrapper-1"
          @click="scrollto('contact')"
        >
          <span class="Menu2-label-1">联系我们</span>
        </div>
      </div>
      <div class="Menu2-body-i4">
        <div class="Menu2-layer-1" />
        <div
          class="Menu2-word-wrapper-1"
          @click="scrollto('qa')"
        >
          <span class="Menu2-word-1">常见问题</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    }
  },
  methods: {
    scrollto(id) {
      console.log(2333)
      this.$emit('scrollto', id)
    }
  }
}
</script>
<style src="./index.css" />
