<template>
  <div class="mb-mod">
    <Popup
      :overlay="false"
      style="margin-top:12vw"
      v-model="show"
      position="top"
    >

      <Menu
        @scrollto="scrollTo"
        v-if="is_en"
      ></Menu>
      <Menu2
        @scrollto="scrollTo"
        v-if="!is_en"
      ></Menu2>
    </Popup>

    <div class="mb-container-inner">
      <div class="mb-mb-mb">
        <div class="mb-mb-wrapper-inner">
          <img
            class="mb-mb-logo"
            src="./images/img_74824_0_0.png"
          />
          <div class="mb-mb-primary">
        
            <div class="mb-mb-icon-list-wrapper">
              <img
                @click="switch1"
                :class="show?'mb-mb-icon-list2':'mb-mb-icon-list'"
                :src="icon_url"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Popup } from 'vant'
import Menu from './menu/index'
import Menu2 from './menu2/index'
export default {
  components: { Popup, Menu, Menu2 },

  data() {
    return {
      show: false,
      icon_url: require('./images/img_74824_0_2.png'),
      is_en: false,
      translate_pic: require('../../images/en.png')
    }
  },
  methods: {
    switch1() {
      if (this.show) {
        this.icon_url = require('./images/img_74824_0_2.png')
      } else {
        this.icon_url = require('./images/ic_close.png')
      }
      this.show = !this.show
    },
    scrollTo(id) {
      document.getElementById(id)?.scrollIntoView()
      this.show = false
      this.icon_url = require('./images/img_74824_0_2.png')
    },
    getContainer() {
      return document.querySelector('.my-container')
    },
    handleCommand() {
      let command = 'cn'
      if (!this.is_en) {
        command = 'en'
        this.translate_pic = require('../../images/en.png')
      } else {
        this.translate_pic = require('../../images/ch.png')
      }
      // 点击切换为英文
      if (command === 'en') {
        // command 是element-ui 下拉框的用法，具体可参考官方文档
        this.lang = 'en'
        this.is_en = true // 当点击切换成英文后，此样式生效（因样式问题自己单独写的）
      } else {
        this.lang = 'cn'
        this.is_en = false
      }
      localStorage.lang = command
      this.$i18n.locale = command
      location.reload()
      // this.$store.commit('switchLang', this.lang) // 这里的switchLang是调用store/index.js中mutations里的switchLang
    }
  },

  created() {
    // 点击切换英文后调用此样式（这里是点击英文后页面部分的样式有点变化，我自己单独写的动态class方法）
    if (localStorage.lang === 'en') {
      this.translate_pic = require('../../images/ch.png')
      this.is_en = true
    } else {
      this.translate_pic = require('../../images/en.png')
      this.is_en = false
    }
  }
}
</script>
<style src="./index.response.css" />
