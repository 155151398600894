<template>
  <div class="Foot-mod">
    <div class="Foot-body">
      <div class="Foot-wrapper-inner">
        <img
          class="Foot-logo"
          src="./images/img_74839_0_0.png"
        />
        <div class="Foot-group">
          <div class="Foot-view">
            <span class="Foot-tag">关于我们</span>
            <span class="Foot-label">财商教育</span>
          </div>
          <div class="Foot-view-1">
            <span class="Foot-word">合作伙伴</span>
            <span class="Foot-tag-1">联系我们</span>
          </div>
        </div>
      </div>
      <div class="Foot-wrapper-inner-1">
        <span class="Foot-label-1">马太效应</span>
        <span class="Foot-word-1">常见问题</span>
      </div>
      <img
        class="Foot-large-icon"
        src="./images/img_74839_0_1.png"
      />
    </div>
    <div class="Foot-footer">
      <span class="Foot-summary">© 上海马甘大卫科技有限公司
        网站备案/许可证号：沪ICP备2020028956号-1</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    }
  },
  methods: {}
}
</script>
<style scoped src="./index.response.css" />
