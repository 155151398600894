<template>
  <div class="Qaq-mod">
    <div class="Qaq-group">
      <img
        class="Qaq-bg"
        src="./images/img_74838_0_0.png"
      />
      <div class="Qaq-header-i0">
        <span class="Qaq-tag">{{$t("qa.qt1")}}</span>
        <img
          class="Qaq-icon-add"
          @click="show1=!show1"
          :src="show1?close:open"
        />
      </div>
      <div
        class="qa-container"
        v-if="show1"
      >
        <span class="qa-content">
          {{$t("qa.q1")}}
        </span>
      </div>
      <div class="Qaq-header-i1">
        <span class="Qaq-info">{{$t("qa.qt2")}}</span>
        <img
          class="Qaq-icon-add-1"
          @click="show2=!show2"
          :src="show2?close:open"
        />
      </div>
      <div
        class="qa-container"
        v-if="show2"
      >
        <span class="qa-content">
          {{$t("qa.q2")}}
        </span>
      </div>
      <div class="Qaq-header-i2">
        <span class="Qaq-meta">{{$t("qa.qt3")}}</span>
        <img
          class="Qaq-icon-add-2"
          @click="show3=!show3"
          :src="show3?close:open"
        />
      </div>
      <div
        class="qa-container"
        v-if="show3"
      >
        <span class="qa-content">
          {{$t("qa.q3")}}
        </span>
      </div>
      <div class="Qaq-header-i3">
        <span class="Qaq-subtitle">{{$t("qa.qt4")}}</span>
        <img
          class="Qaq-icon-add-3"
          @click="show4=!show4"
          :src="show4?close:open"
        />
      </div>
      <div
        class="qa-container"
        v-if="show4"
      >
        <span class="qa-content">
          {{$t("qa.q4")}}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      close: require('./images/ic_close.png'),
      open: require('./images/ic_open.png'),
      constants: {}
    }
  },
  methods: {}
}
</script>
<style scoped src="./index.response.css" />
