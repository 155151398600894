import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18n from './i18n/i18n'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import router from './router'
import App from './App.vue'
import Mobile from './Mobile.vue'
// import EN_PC from './EN_PC.vue'
// import EN_MOBILE from './EN_MOBILE.vue'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.config.productionTip = false
let isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
let fun = null
if(isMobile) {
  fun = Mobile
} else {
  fun = App
}


Vue.use(VueI18n)

new Vue({
  i18n,
  router,
  render: h => h(fun),
}).$mount('#app')