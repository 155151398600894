<template>
  <div class="moda1">
    <div class="wrapper1">
      <div class="body1">
        <div class="group1">
          <div class="view">
            <img
              class="logo"
              src="./images/img_74236_0_0.png"
            />
          </div>
          <div class="view-1">
            <div
              v-html="text"
              class="caption"
            ></div>
          </div>
          <a
            href="#activity"
            class="view-2"
          >
            <span class="detail">{{btn}}</span>
          </a>
        </div>
      </div>
      <div class="footer">
        <div class="mask" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: '',
      btn: '',
      constants: {}
    }
  },
  methods: {},
  created() {
    if (localStorage.lang === 'en') {
      this.text =
        'China-Israel Youth Innovation&<br>Entrepreneurship Contest（YIEC）'
      this.btn = 'Learn more'
    } else {
      this.text = '国际青少年创业创新大赛（YIEC）'
      this.btn = '了解详情'
    }
  }
}
</script>
<style scoped src="./index.response.css" />
